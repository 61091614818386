@include media-breakpoint-down(md) {
  .hero-title__content {
    bottom: 0;
  }

  .hero-title__content--align-center {
    top: 8%;

    .container {
      padding-right: 0.938rem;
      padding-bottom: 0.938rem;
    }
  }
}