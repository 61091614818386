.see-more-button {
  text-transform: uppercase;
  font-weight: $font-weight-medium;
  color: white;
  font-size: $see-more-button-font-size;

  i {
    margin-left: 5px;
    //font-size: $font-size-sm * 0.85;
    font-size: 85%;
  }

  &:hover {
    color: white;
  }

  &.see-more-button--reversed {
    color: $gray;

    &.hover {
      font-weight: $font-weight-bold;
    }
  }
}