@include media-breakpoint-up(lg) {
  .preview-virtual-rom--img {
    width: 40rem;
  }

  .blue-box {
    width: 64%;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  .paragraph {
    width: 85%;
  }
}

// WARNING: use this for large screens
@media (min-width: 1441px) {
  .preview-virtual-rom--img {
    width: 64rem;
  }

  .preview-virtual-rom--img {
    height: 32.5rem;
  }

  .paragraph {
    width: 60%;
  }
}
