@include media-breakpoint-up(lg) {
  .hero-title__content {
    bottom: 25%;

    .container {
      padding-right: $sub-hero-shape-width-lg * .8;
      padding-bottom: $sub-hero-shape-height-lg * .15;
    }
  }

  .hero-title__content--align-center {
    top: 8%;

    .container {
      padding-right: 0;
      padding-bottom: 0;
    }
  }

  .hero-title-heading {
    font-size: $h1-font-size * 1.3;
  }
}
