@include media-breakpoint-up(lg) {
  .sub-hero-content {
    padding-top: $sub-hero-shape-height-lg;
    margin-top: -$sub-hero-shape-height-lg;

    &:after {
      width: $sub-hero-shape-width-lg;
      height: $sub-hero-shape-height-lg;
      right: 0;
    }
  }

  .sub-hero-content--hide-shape {
    padding-top: 0;
    margin-top: 0;
  }
}