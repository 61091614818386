.spglobal-footer {
  background-color: $gunmetal;

  p, h5, a, form, label, button {
    font-size: $font-size-sm;
  }

  p {
    color: $gray-light;
  }

  h5, a {
    color: white;
  }

  h5 {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
}

.footer-links {
  a {
    font-weight: $font-weight-medium;
  }

  &:not(.footer-links--social) {
    a {
      display: block;
    }
  }

  &.footer-links--instances {
    a {
      text-transform: uppercase;
    }
  }

  &.footer-links--social {
    a {
      i {
        color: #9B9B9B;
      }
    }
  }
}

.footer-copyright {
  padding: 2rem 0 2.3rem;

  img {
    height: 40px;
    width: auto;
    margin-bottom: 5px;
    opacity: .4;
  }

  p {
    font-size: $font-size-xs;
  }
}