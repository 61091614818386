.divisor--img {
  min-height: 416px;
  position: relative;
  background-color: $skeleton-background;
  background-size: cover;
  background-position: center center;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}