.how-works-container {
  display: flex;
  position: relative;
}

.preview-virtual-rom--img {
  height: 22.5rem;
}

.blue-box {
  background-color: $gunmetal;
  color: $white;
}