.hero-title-showroom {
  height: 100vh;
  min-height: 320px;
  position: relative;
  background-color: $skeleton-background;
  background-size: cover;
  background-position: center center;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.hero-title-showroom__content {
  position: absolute;
  width: 100%;
  color: white;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}