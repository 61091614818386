// for links with color class names
// example: <a class="colored-link gray"/>
a.colored-link {
  &:hover,
  &:active,
  &:focus {
    color: inherit;
    text-decoration: none;
  }
}

a.a-inherit {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: inherit;
    text-decoration: underline;
  }
}

button.as-link {
  background-color: transparent;
  border: none;
  display: inline;
  padding: 0;
  outline: none;
  box-shadow: none;
  color: inherit;
}

.cursor-pointer {
  cursor: pointer;
}
