.modal-header {
  position: relative;

  .close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1rem;
    margin: 0;
  }
}

.modal-title {
  width: 100%;
}

h5.modal-title {
  font-family: $headings-font-family;
  font-size: $h3-font-size;
}

.modal-body {
  background-color: $modal-body-background-color;
}

.modal-footer {
  justify-content: center;
}

@include media-breakpoint-up(lg) {
  h5.modal-title {
    font-size: $h1-font-size;
    text-align: center;
  }

  .modal-header,
  .modal-body,
  .modal-footer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
