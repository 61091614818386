// Type
$font-family-sans-serif:      "Gramatika", sans-serif;
$font-family-base:            $font-family-sans-serif;
$headings-font-family:        "Dala Moa", sans-serif;
$font-family-cursive:         "Eternate", sans-serif;

// Colors
$dark-cyan: #04A0A4;
$very-dark-cyan: #046E70;
$cyan-darker: #00575F;
$gray: #4A4A4A;
$gray-light: #777777;
$gray-lighter: #F9F8F5;
$pink: #FCD8E0;
$light-grayish-yellow: #F9F8F5;
$skeleton-background: #f1f1f1;
$dark-magenta: #983596;
$green: #0C7C62;
$green-light: #8DC63F;
$sizzling-red: #F05D5E;
$gunmetal: #273343;

$primary: $orange;
$secondary: $very-dark-cyan;
$dark: black;

// Grid
// /\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/
$grid-extra-small-gutter-width: 8px;
$grid-small-gutter-width:       16px;
$grid-medium-gutter-width:      24px;
$grid-gutter-width-base:        $grid-gutter-width !default;
$grid-large-gutter-width:       35px;
$grid-extra-large-gutter-width: 40px;

$grid-gutter-widths: (
        extra-small: $grid-extra-small-gutter-width,
        small: $grid-small-gutter-width,
        medium: $grid-medium-gutter-width,
        regular: $grid-gutter-width-base,
        large: $grid-large-gutter-width,
        extra-large: $grid-extra-large-gutter-width
);

// Theme colors
$theme-colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark,
    "gunmetal":   $gunmetal,

  ),
  $theme-colors
);

// font weight
$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-light: 300;
$font-weight-medium: 500;
$font-weight-black: 900;

// font size
$font-size-xs: ($font-size-base * .75);

// grid gutter width
$grid-gutter-width-half: $grid-gutter-width / 2;

// modals
$modal-body-background-color: $gray-lighter;
