.text-weight-light      { font-weight: $font-weight-light; }
.text-weight-bold       { font-weight: $font-weight-bold; }
.text-weight-normal     { font-weight: $font-weight-normal; }
.text-weight-medium     { font-weight: $font-weight-medium; }
.text-weight-black      { font-weight: $font-weight-black; }

.text-underline         { text-decoration: underline; }

.text-capitalize        { text-transform: capitalize;}
.text-uppercase        { text-transform: uppercase;}

.text-family-base       { font-family: $font-family-base; }
.text-family-alt        { font-family: $headings-font-family; }
.text-family-cursive    { font-family: $font-family-cursive; }

.text-size-small { font-size: $font-size-sm; }
.text-size-extra-small { font-size: $font-size-xs; }
.text-size-large { font-size: $font-size-lg; }

@include font-sizes;
@include media-breakpoint-up(sm) { @include font-sizes($prefix: '-sm'); }
@include media-breakpoint-up(md) { @include font-sizes($prefix: '-md'); }
@include media-breakpoint-up(lg) { @include font-sizes($prefix: '-lg'); }
@include media-breakpoint-up(xl) { @include font-sizes($prefix: '-xl'); }
