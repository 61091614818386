.sp_showroom-header {
  height: $header-height;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
}

.header-logo {
  height: 32px;
  margin-left: 9.5%;
}

.language-container {
  color: $white;
}

.language-label:hover {
  font-weight: $font-weight-bold;
}