@include media-breakpoint-down(md) {
  .how-works-container {
    display: block;
    text-align: center;
  }

  .preview-virtual-rom--img {
    width: 100%;
    height: 22.5rem;
  }

  .blue-box {
    text-align: left;
  }
}