@mixin colorize-sub-hero-content($bg: pink, $color: black) {
  color: $color;

  &:after {
    background-color: $bg;
  }

  .sub-hero-content__wrapper {
    background-color: $bg;
  }

  .title-underlined {
    color: $color;

    &:after {
      background-color: $color;
    }
  }
}