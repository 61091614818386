@include media-breakpoint-up(md) {
  .spglobal-footer {
    padding-top: 5rem;

    h5 {
      font-size: $font-size-base;
    }
  }

  .footer-links--instances {
    margin-bottom: 2rem;

    a {
      font-size: $font-size-sm;
    }
  }

  .footer-links--misc {
    padding-top: 2rem;
  }

  .footer-links--social {
    a {
      display: block;
      margin-bottom: 5px;

      i {
        font-size: $font-size-lg;
        position: relative;
        top: 2px;
      }
    }
  }

  .footer-copyright {
    padding: 4rem 0;
  }
}